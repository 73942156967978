import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Cookies from 'js-cookie';
import {
  Admin,
  Archives,
  HomePage,
  GamePage,
  LoginPage,
  LoadingPage,
  MerchPage,
  PlayerPageContainer,
  RefsPage,
  RinkPage,
  RulesPage,
  SchedulePage,
  ScorekeepingPage,
  StatsPage,
  TeamPageContainer,
} from 'Components';
require('dotenv').config();

const { REACT_APP_AUTH_COOKIE, REACT_APP_AUTH_TOKEN } = process.env;

const Routes = () => {
  const authCookie = Cookies.get(REACT_APP_AUTH_COOKIE);

  if (!authCookie || authCookie !== REACT_APP_AUTH_TOKEN) {
    return <LoginPage />;
  }

  return (
    <Switch>
      <Route path="/admin">
        <Admin />
      </Route>
      <Route path="/archives">
        <Archives />
      </Route>
      <Route exact path="/">
        <HomePage />
      </Route>
      <Route exact path="/home">
        <HomePage />
      </Route>
      <Route exact path="/rules">
        <RulesPage />
      </Route>
      <Route exact path="/refs">
        <RefsPage />
      </Route>
      <Route exact path="/scorekeeping">
        <ScorekeepingPage />
      </Route>
      <Route exact path="/game/:id">
        <GamePage />
      </Route>
      <Route exact path="/player/:id">
        <PlayerPageContainer />
      </Route>
      <Route exact path="/rink/:id">
        <RinkPage />
      </Route>
      <Route exact path="/schedule/:id">
        <SchedulePage />
      </Route>
      <Route exact path="/standings/:id">
        Standings
      </Route>
      <Route exact path="/stats/:id">
        <StatsPage />
      </Route>
      <Route exact path="/merch">
        <MerchPage />
      </Route>
      <Route exact path="/loading">
        <LoadingPage />
      </Route>
      <Route exact path="/team/:seasonId/:teamId">
        <TeamPageContainer />
      </Route>
    </Switch>
  );
};

export default Routes;
