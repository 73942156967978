import React, { useEffect, useState } from 'react';
import { InfoPageTemplate } from 'Components';
import refsMd from 'data/refs.md';
import positioning from 'assets/positioning.jpg';

const RefsPage = () => {
  const [refsText, setRefsText] = useState('');

  useEffect(() => {
    fetch(refsMd)
      .then((res) => res.text())
      .then((text) => setRefsText(text));
  });

  return (
    <InfoPageTemplate
      image={positioning}
      imageAlt="Positioning Guidelines"
      markdownText={refsText}
      olType="numeric"
      pageTitle="Referee Guidelines"
    />
  );
};

export default RefsPage;
