export { convertTimestampToSeconds } from './convertTimestampToSeconds';
export { calculateWinStreak } from './calculateWinStreak';
export { design } from './design';
export { formatTime } from './formatTime';
export { getGameStars } from './getGameStars';
export { getLastWeekDate, getNextWeekDate, getTodayDate } from './dates';
export { getPeriodTitle } from './getPeriodTitle';
export { getPlayerGameStatsGamePage, getPlayerGameStatsSchedulePage } from './getPlayerGameStats';
export { getWinner } from './getWinner';
export { processAttendance } from './processAttendance';
export { makeTitle } from './makeTitle';
export { sortPlayersByName } from './sortPlayersByName';
export { sortPlayersByNumber, sortPlayersByNumberNested } from './sortPlayersByNumber';
export { sortPlayerStats } from './sortPlayerStats';
export { sortStandings } from './sortStandings';

// Stat Data Functions
export {
  createGoalieStatsCareerSeasons,
  createGoalieStatsCareerTotals,
} from './statDataFunctions/createGoalieStatsCareer';
export { createGoalieStatsLeague } from './statDataFunctions/createGoalieStatsLeague';
export { createGoalieStatsSeason } from './statDataFunctions/createGoalieStatsSeason';
export {
  createPlayerExportData,
  createPlayerExportTotalData,
} from './statDataFunctions/createPlayerExportData';
export { createSeasonStatData } from './statDataFunctions/createSeasonStatData';
export {
  createSkaterStatsCareerSeasons,
  createSkaterStatsCareerTotals,
} from './statDataFunctions/createSkaterStatsCareer';
export { createSkaterStatsSeason } from './statDataFunctions/createSkaterStatsSeason';
export { createStandingsData } from './statDataFunctions/createStandingsData';
export {
  createStatTotalData,
  createStatTotalDataWithTeam,
} from './statDataFunctions/createStatTotalData';
export {createTeamGoalieData} from './statDataFunctions/createTeamGoalieData';
export {createTeamSkaterData} from './statDataFunctions/createTeamSkaterData';

export const CURRENT_SEASON = 37;
