import React, { useEffect, useState } from 'react';
import { InfoPageTemplate } from 'Components';
import scorekeepingMd from 'data/scorekeeping.md';
import scoresheet from 'assets/scoresheet.jpg';

const ScorekeepingPage = () => {
  const [scorekeepingText, setScorekeepingText] = useState('');

  useEffect(() => {
    fetch(scorekeepingMd)
      .then((res) => res.text())
      .then((text) => setScorekeepingText(text));
  });

  return (
    <InfoPageTemplate
      image={scoresheet}
      imageAlt="Example Scoresheet"
      markdownText={scorekeepingText}
      olType="numeric"
      pageTitle="Scorekeeping"
    />
  );
};

export default ScorekeepingPage;
