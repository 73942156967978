import React, { useEffect, useState } from 'react';
import { InfoPageTemplate } from 'Components';
import rulesMd from 'data/rules.md';

const RulesPage = () => {
  const [rulesText, setRulesText] = useState('');

  useEffect(() => {
    fetch(rulesMd)
      .then((res) => res.text())
      .then((text) => setRulesText(text));
  });

  return (
    <InfoPageTemplate markdownText={rulesText} olType="lower-alpha" pageTitle="League Rules" />
  );
};

export default RulesPage;
